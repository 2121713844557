import { get, set } from 'solid-utils/access';
import { createEffect, createSignal, on } from 'solid-js';
import type { Signal } from 'solid-js';

import { animateProps } from '../appearance';

import TextArea from 'ui/elements/text-area';

export default function TaskDescription(props: {
  model: Signal<string>;
}) {
  const textArea = createSignal<HTMLTextAreaElement>();
  const wasEdited = createSignal(false);

  createEffect(on(props.model[0], () => {
    set(wasEdited, true);
  }, { defer: true }));

  createEffect(() => console.log(getHeightOfLines(6)));

  return <>
    <div {...animateProps}
      class="pt-1.5 pb-2 [&[data-appearance]]:animate-init-fade-in-200 overflow-hidden"
    >
      <TextArea model={props.model} ref={el => set(textArea, el)}
        data-id="task-description"
        class="**:app-text-body-l/regular-long-stable"
        autofocus
        placeholder="Description"
        maxLines={getHeightOfLines(6)}
      />
    </div>
  </>;

  function getHeightOfLines(lines: number) {
    const ta = get(textArea);

    if (/* get(wasEdited) || */ !ta) {
      return undefined;
    }

    const lineHeight = ta.computedStyleMap().get('line-height')?.toString();

    if (!lineHeight) {
      return undefined;
    }

    const lineX = Number(lineHeight.replace(/(px|r?em)$/, ''));
    const linePixels = lineHeight.endsWith('px') ? lineX : (lineX * 16);

    return linePixels * lines;
  }
}