import { animateProps } from '../../appearance';

import Status from '#/task-editor/ui/elements/status';

import Priority from '../../elements/priority';

import More from 'icons/24/More Horizontal Outlined.svg';

export default function StatusBar() {
  return <>
    <div class="flex items-center justify-between p-1 ltr:pl-4 rtl:pr-4" {...animateProps}>
      <div class="flex-grow">
        <Status />
      </div>
      <div class="flex items-center gap-1">
        <Priority />
        <button data-id="task-editor-context-menu"
          class="p-1.5"
        >
          <More class="fill-hint opacity-50" />
        </button>
      </div>
    </div>
  </>;
}