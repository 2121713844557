import { onMount } from 'solid-js';

/**
 * Allows to generate appearance animations for any number of elements on the page
 *
 * To use, simply deconstruct the results:
 * ```ts
 * export const { animateProps, useWaterfallRoot } = createElementWaterfall('animate-fade-in')
 * ```
 *
 * And:
 * - spread the `animateProps` into every native element's props that you want to animate
 * - call `useWaterfallRoot` in the root component - a waterfall-origin point
 *
 * @param className the class name that applies an animation, for example `animate-init-fade-in`
 * @param options delay options
 *
 * The algorithm implements a sqaure-root delay function via hyperbolic delay-delta accumulation,
 * by subtracting the delay-delta from the next element's delay,
 * which gives an exponential increase in element appearance speed.
 *
 * For example, with the default parameters of `{ delay = 50, delayDelta = 2, lowerLimit = 10 }`,
 * the elemets will appear with the following delays:
 * ```
 * #0: 48ms
 * #1: 94ms (#0 + ~100%)
 * #2: 138ms (#1 + ~46%)
 * #3: 180ms (#2 + ~30%)
 * #4: 220ms (#3 + ~22%)
 * #5: 258ms (#4 + ~17%)
 * ...etc
 * ```
 * giving a decreasing increase in appearance speed
 * to reduce user waiting time for pages with lots of elements.
 * The more elements on the page, the more dramatic the effect is.
 *
 * Setting the delay-delta to `0` will make the appearance delays linear.
 *
 * The lower-limit simply prevents the delay-delta from decreasing lower than this.
 * @returns
 */
export function createElementWaterfall(className: string, options: {
  delay?: number;
  delayDelta?: number;
  lowerLimit?: number
} = {}) {
  const { delay = 50, delayDelta = 2, lowerLimit = 10 } = options;

  return {
    /**
     * Call this in the root component - a waterfall-origin point
     */
    useWaterfallRoot: () => onMount(() => {
      let hyperbolicDelay = delay;
      let cumullativeDelay = 0;

      document.querySelectorAll(`[data-appearance="${className}"]`).forEach(el => {
        el.classList.add(className);

        hyperbolicDelay = Math.max(hyperbolicDelay - delayDelta, lowerLimit);

        const finalDelay = cumullativeDelay += hyperbolicDelay;

        if (el instanceof HTMLElement) {
          (el as HTMLElement).style.animationDelay = `${finalDelay}ms`;
        }
      });
    }),

    /**
     * Spread this into every native element's props that you want to animate
     */
    animateProps: {
      ['data-appearance']: className,
    },
  };
}