import { createEffect, createMemo, Index } from 'solid-js';

import Item from './item';
import { getSubtaskId } from './controller';
import type { Subtask, SubtaskController } from './controller';

export { Subtask };

export default function (props: {
  list: readonly Subtask[];
  updateItem: (value: string, index?: number) => void;
  toggleItem: (value: boolean, index: number) => void;
  removeItem: (index: number) => void;
  addItem: () => void;
}) {
  let container!: HTMLDivElement;

  class ItemController implements SubtaskController {
    constructor(
      private index: number,
    ) {}

    onInput(val: string) {
      props.updateItem(val, this.index);

      if (val === '') {
        props.list.length > 1 && props.removeItem(this.index);
      }
    }

    addNew() {
      if (this.index === props.list.length - 1) {
        props.addItem();

        return true;
      }

      return false;
    }

    selectInput(offset: number) {
      const offsetInputId = getSubtaskId(this.index + offset);
      const offsetInput = container.querySelector<HTMLInputElement>(`[data-id="${offsetInputId}"]`);

      if (!offsetInput) return;

      offsetInput.focus();
    }

    onCheckChange(checked: boolean) {
      if (props.list.length === 0) {
        return;
      }

      props.toggleItem(checked, this.index);
    }
  }

  const renderedList = createMemo(() => props.list.length > 0 ? props.list : [{ done: false, title: '' }]);

  return <div data-id="subtask-list" ref={el => container = el}>
    <Index each={renderedList()}>{(item, index, controller = new ItemController(index)) =>
      <Item value={item} index={index}
        subtask={controller}
      />
    }</Index>
  </div>;

}