import type { Resource } from 'solid-js';

import type { FullClientTask } from 'f/task/task.adapter';

import TaskEditorForm from './form';

export default function TaskEditor(props: {
  task?: Resource<FullClientTask>;
}) {
  return <>
    <section data-id="task-editor">
      <TaskEditorForm />
    </section>
  </>;
}