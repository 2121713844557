import { Show } from 'solid-js';

import { isIOS } from 'shared/platform';

import { FilesList } from 'f/task/editor.ui/files';
import { defaultProject } from 'f/project/project.context';

import { animateProps, useWaterfallRoot } from '../appearance';

import TopCard from './top-card';
import TaskHeader from './task-header';

export default function TaskEditorForm() {
  useWaterfallRoot();

  const fd = new FormData();

  return <div role="form">
    <TaskHeader
      taskKey="UT-123"
      projectName={defaultProject.name}
    />

    <div class="p-4 pt-2 flex flex-col gap-4" {...animateProps}>
      <TopCard />

      <div class="[&>div>div>*:first-child]:(bg-section_bg! rounded-3)" {...animateProps}>
        <FilesList formData={fd} />
      </div>
    </div>

    <Show when={isIOS()}>
      <div class="h-25vh" />
    </Show>
  </div>;
}