import { Show } from 'solid-js';

import { animateProps } from '../appearance';

import UnfoldMore from 'icons/UnfoldMore.svg';

export default function TaskHeader(props: {
	projectName: string;
	taskKey?: string;
}) {
	return <>
		<header class="flex flex-nowrap items-center justify-between p-2 pb-0" {...animateProps}>
			<button
				data-id="project-selector"
				type="button"
				class="flex-grow flex items-center gap-1 p-2"
			>
				<p data-id="project-title" class="app-text-body-l/regular c-hint">
					{props.projectName}
				</p>
				<UnfoldMore class="inline fill-hint" />
			</button>
			<Show when={props.taskKey}>
				<div class="flex items-center gap-1 p-2">
					<p data-id="task-readable-id" class="app-text-body-l/regular c-hint">
						{props.taskKey}
					</p>
				</div>
			</Show>
		</header>
	</>;
}
