import { get, set } from 'solid-utils/access';
import { children, createEffect, createSignal, Match, on, Show, Switch } from 'solid-js';
import type { JSX } from 'solid-js';

import { isIOS } from 'shared/platform';

import { animateProps } from '../../appearance';

import { getSubtaskId } from './controller';
import type { Subtask, SubtaskController } from './controller';

import TextArea from 'ui/elements/text-area';

import PlusOutlined from 'icons/20/Plus Outlined.svg';
import Check from 'icons/20/Checkmark Circle Outlined.svg';
import CheckFilled from 'icons/20/Checkmark Circle Filled.svg';

export default function (props: {
  index: number;
  value: () => Subtask;
  subtask: SubtaskController;
  children?: (text: string) => JSX.Element;
}) {
  let input!: HTMLInputElement | HTMLTextAreaElement;
  let markedForRemoval = false;

  const realValue = createSignal(props.value().title);
  const checked = createSignal(props.value().done);

  createEffect(on(checked[0], () => {
    props.subtask.onCheckChange(get(checked));
  }, { defer: true }));

  const formatted = children(() => props.children?.(get(realValue)) ?? get(realValue));

  return <>
    <div
      data-id={getSubtaskId(props.index) + '-container'}
      class="
        relative
        flex items-center ltr:pl-0.5 rtl:pr-0.5 py-2 gap-4.5
        b-b-solid b-b b-b-border last:b-b-transparent! app-transition-border
        animate-init-fade-in-down-100
        overflow-hidden
      "
      {...animateProps}
    >
      {/* TODO: replace this ad-hoc checkbox with a proper one */}
      <div data-id={getSubtaskId(props.index) + '-checkbox'}
        onClick={() => {
          if (props.value().title === '') {
            input.focus();
            return;
          }

          set(checked, old => !old);
        }}
        class="cursor-pointer w-8 h-8 m--1.5 p-1.5 flex items-center"
      >
        <Switch fallback={<PlusOutlined class="absolute fill-hint opacity-50" />}>
          <Match when={props.value().title && !get(checked)}>
            <Check class="absolute fill-hint opacity-50 hover:opacity-100 transition-opacity transition-duration-100" />
          </Match>
          <Match when={props.value().title && get(checked)}>
            <CheckFilled class="absolute fill-hint opacity-50 hover:opacity-100 transition-opacity transition-duration-100" />
          </Match>
        </Switch>
      </div>

      <TextArea renderInput={itemInput} class="flex-grow">{text =>
        <>{text}</>
      }</TextArea>
    </div>
  </>;

  function itemInput(): JSX.Element {
    return <input
      data-id={getSubtaskId(props.index)}
      class="flex-grow app-text-body-l/regular-long my--1 w-full"
      classList={{ 'animate-init-slide-in-down-200': !isIOS() }}
      placeholder="List"
      value={props.value().title}
      onChange={e => props.subtask.onInput(e.target.value.trim())}
      onInput={e => set(realValue, e.currentTarget.value.trim())}
      onKeyUp={e => {
        const oldValue = props.value().title;
        const newValue = e.currentTarget.value.trim();

        switch (e.key) {
          case 'Enter': {
            if (oldValue === '') break;

            props.subtask.addNew();
            props.subtask.selectInput(+1);
          } break;

          case 'Backspace': {
            // Already empty - delete item and select previous
            if ((oldValue === '' && newValue === '') || markedForRemoval) {
              props.subtask.onInput('');
              props.subtask.selectInput(-1);
              return;
            }
          } break;
        }

        // Will be empty - mark for removal on next Backspace press
        if (newValue === '' && !markedForRemoval) {
          markedForRemoval = true;
        } else {
          markedForRemoval = false;
        }
      }}
    />;
  }
}