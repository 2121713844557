import { lazy, Match, Switch } from 'solid-js';

import Undefined from 'icons/24/Priority/Not set.svg';
import Medium from 'icons/24/Priority/Medium.svg';
import Lowest from 'icons/24/Priority/Lowest.svg';
import Low from 'icons/24/Priority/Low.svg';
import Highest from 'icons/24/Priority/Highest.svg';
import High from 'icons/24/Priority/High.svg';

export default function Priority(props: {
  priority?: string;
}) {

  return <button class="p-1.5">
    <Switch fallback={<Undefined class="fill-hint opacity-50" />}>
      <Match when={false}>
        <Low />
      </Match>
    </Switch>
  </button>;
}