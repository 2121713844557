import { model, useDirectives } from 'solid-utils/model';
import { get, set } from 'solid-utils/access';
import type { JSX } from 'solid-js';
import { children, createMemo, createSignal, mergeProps, Show } from 'solid-js';
import type { ComponentProps, Signal } from 'solid-js';
import { omit } from 'rambda';

/**
 * Like the native text-area, but auto-resizes to text contents
 *
 * Has a plain div container, where `class` and `classList` attributes will apply;
 *
 * Other attributes go straight to native text-area
 *
 * If `renderInput` is set, `children` is ignored.
 * `children` allows for custom text overlay rendering, which is obsolete when rendering a custom input
 */
export default function TextArea(_props: Omit<ComponentProps<'textarea'>, 'children'> & {
  maxLines?: number | undefined;
  model?: Signal<string>;
  children?: (text: string) => (JSX.Element);
  renderInput?: (defaultProps: DefaultProps) => (JSX.Element);
}) {
  const props = mergeProps({ model: createSignal('') }, _props);

  useDirectives(model);

  /* the space prevents span not accounting for full line-height at empty trailing lines */
  const overlayText = () => get(props.model) + ' ';

  const inputProps = createMemo(() => ({
    class: 'absolute w-full h-full overflow-hidden resize-none p-0 z-1',
    classList: { 'pb-1': get(props.model).endsWith('\n') },

    onFocus: props.children ? onFocus : undefined,
    onFocusIn: props.children ? onFocus : undefined,
    onFocusOut: props.children ? onBlur : undefined,
    onBlur: props.children ? onBlur : undefined,
  }));

  const isFocused = createSignal(false);
  const resolved = children(() => props.children?.(overlayText()) || overlayText());
  const customInput = children(() => props.renderInput?.(inputProps()));

  return <div class="max-w-full relative overflow-hidden"
    classList={{
      [String(props.class)]: !!props.class,
      ...props.classList,
    }}
  >
    <Show fallback={customInput()}
      when={!props.renderInput}
    >
      <textarea use:model={props.model}
        {...inputProps}
        {...omit(['class', 'classList', 'model', 'maxLines', 'children', 'ref', 'renderInput'], props)}
      />
    </Show>

    <Show when={!props.renderInput}>
      <span // hack-element to make the text-area auto-resize itself
        class="relative resize-none p-0 whitespace-pre-wrap pointer-events-none z-2"
        aria-hidden={get(isFocused)}
        classList={{ 'invisible': get(isFocused) }}
      >
        {resolved()}
      </span>
    </Show>
  </div>;

  function onFocus() {
    if (!props.children) return;

    set(isFocused, true);
  }

  function onBlur() {
    if (!props.children) return;

    set(isFocused, false);
  }
}

type DefaultProps = {
  class: string;
  classList: Record<string, boolean>;
  onFocus: (() => void) | undefined;
  onFocusIn: (() => void) | undefined;
  onFocusOut: (() => void) | undefined;
  onBlur: (() => void) | undefined;
};