import type { Signal } from 'solid-js';

import { animateProps } from '../appearance';

import TextArea from 'ui/elements/text-area';


export default function TaskTitle(props: {
  model: Signal<string>;
}) {
  return <>
    <div {...animateProps}
      class="px-4 pt-3 pb-2"
    >
      <TextArea model={props.model}
        data-id="task-title"
        class="**:app-text-title-s/medium-stable"
        autofocus
        placeholder="Title"
      />
    </div>
  </>;
}