import { createMutable, createStore, produce } from 'solid-js/store';
import { createSignal } from 'solid-js';

import StatusBar from './status-bar';

import TaskTitle from '#/task-editor/ui/elements/title';

import Subtasks from '../../elements/subtasks';
import type { Subtask } from '../../elements/subtasks';
import TaskDescription from '../../elements/description';

import CheckboxOutlined from 'icons/24/Checkbox Outlined.svg';
import CalendarAdd from 'icons/24/Calendar Add Outlined.svg';
import WebApp from 'tma-dev-sdk';

export default function TopCard(props: {}) {
	const title = createSignal('');
	const description = createSignal('');
	const [subtasks, updateSubtasks] = createStore<Subtask[]>([]);

	return <>
		<div class="bg-section_bg flex flex-col rounded-3">
			<div class="flex items-center w-full py-1 px-2.5">
				<button class="p-1.5">
					<CheckboxOutlined class="fill-hint opacity-50" />
				</button>
				<p class="c-accent_text app-text-body-m/medium flex-grow text-center">
					Сегодня, 18:00
				</p>
				<CalendarAdd class="fill-hint opacity-50" />
			</div>
			<TaskTitle model={title} />

			<div data-id="task-description-container" class="px-4 min-h-35vh">
				<TaskDescription model={description} />

				<Subtasks list={subtasks}
					removeItem={index => updateSubtasks(produce(subtasks => subtasks.splice(index, 1)))}
					addItem={() => updateSubtasks(produce(subtasks => subtasks.push({ title: '', done: false })))}
					toggleItem={(val, index) => {
						if (!subtasks[index]) return;

						updateSubtasks(produce(subtasks => {
							subtasks[index].done = val;
							WebApp.HapticFeedback.selectionChanged();
						}));
					}}
					updateItem={(val, index) => updateSubtasks(produce(subtasks => {
						if (typeof index === 'undefined' || index === subtasks.length) {
							subtasks.push({
								title: val,
								done: false,
							});

							return;
						}

						if (!subtasks[index]) {
							subtasks[index] = {
								done: false,
								title: val,
							};
						} else {
							subtasks[index].title = val;
						}

						if (index === subtasks.length - 1) {
							subtasks.push({ title: '', done: false });
						}
					}))}
				/>
			</div>

			<StatusBar />
		</div>
	</>;
}
